import dynamic from 'next/dynamic';

const pages = {
  'home.HomePage': dynamic(() => import('./HomePage')),
  'home.BSHomePage': dynamic(() => import('./HomePage')),
  'flex.FlexPage': dynamic(() => import('./FlexPage')),
  'base.LinkPage': dynamic(() => import('./LinkPage')),
  'bonuswelt.BonusweltListingPage': dynamic(
    () => import('./BonusweltListingPage'),
  ),
  NotFoundPage: dynamic(() => import('./NotFoundPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};

export type Pages = keyof typeof pages;

export default pages;
