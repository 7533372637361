// @ts-nocheck

import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import {
  getFooter,
  getMenu,
  getPage,
  //getRequest,
  //getRedirect,
  //getAllPages,
  //getMenu,
  //WagtailApiResponseError,
  //WagtailApiResponseError,
} from '@api/wagtail';
import LazyViews from '@views/LazyViews';
import { PageData } from '@/types';

type Props = { pageData: PageData };

const CatchAllPage: NextPage<Props> = props => {
  const { pageData } = props;

  const Component = LazyViews[pageData.meta.type];
  if (!Component) {
    return <h1>Component {pageData.meta.type} not found</h1>;
  }
  return <Component {...pageData} />;
};

export default CatchAllPage;

export async function getStaticProps({ params }): GetStaticProps {
  params = params || {};
  let path = params.path || [];
  path = path.join('/');

  const [{ json: pageData }, { json: footerData }, { json: menuData }] =
    await Promise.all([getPage(path), getFooter(), getMenu()]);

  return {
    props: { pageData, footerData, menuData },
    revalidate: 1,
  };
}

export async function getStaticPaths(): GetStaticPaths {
  // const { json: data } = await getAllPages();

  // let htmlUrls = data.items.map((x) => x.relativeUrl);
  // htmlUrls = htmlUrls.filter((x) => x);
  // htmlUrls = htmlUrls.map((x) => x.split("/"));
  // htmlUrls = htmlUrls.map((x) => x.filter((y) => y));
  // htmlUrls = htmlUrls.filter((x) => x.length);

  // const paths = htmlUrls.map((x) => ({ params: { path: x } }));

  return {
    paths: [],
    fallback: 'blocking',
  };
}
